<template>
  <div v-if="isAdmin || checkAccess('site-summary')">
    <report-list-page
        page-title="Sites Summary Report"
        page-icon="business"
        :descending="true"
        :headers="headers"
        state-end-point="salesCounter.sitesSummaries"
        :actions="actions"
        :show-component="showComponent"
        sort="siteId"
    />
  </div>
</template>

<script>
import ReportListPage from "../../components/ReportListPage";
import IsAuthenticated from "../../middleware/IsAuthenticated";
export default {
  name: "SiteSummaryReport",
  mixins: [IsAuthenticated],
  components: {ReportListPage},
  data() {
    return {
      headers: [
        {
          text: 'Site ID',
          value: 'siteId'
        },
        {
          text: 'Site URL',
          value: 'siteUrl'
        },
        {
          text: 'Status',
          align: 'left',
          value: 'paymentStatus'
        },
        {
          text: 'Impression',
          value: 'impression'
        },
        {
          text: 'Admin Price',
          align: 'right',
          value: 'totalAdminPrice'
        },
        {
          text: 'Publishable Amount',
          align: 'right',
          value: 'publishableAmount'
        },
        {
          text: 'Writing Price',
          align: 'right',
          value: 'articleWritingPrice'
        },
        {
          text: 'Sub Total',
          align: 'right',
          value: 'total'
        },
        {
          text: 'Discount',
          align: 'right',
          value: 'discount'
        },
        {
          text: 'Total',
          align: 'right',
          value: 'excludeDiscountTotal'
        }
      ],
      actions: {
        load: 'loadSiteSummaryReports',
      },
      showComponent: {
        isUser: true,
        isStatus: true,
        isMethod: false,
        isEmail: true,
        isSite: true,
        isSummary: true,
        isDate: true
      }
    }
  }
}
</script>

<style scoped>

</style>